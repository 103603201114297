const NODE_IDS = {
  BASE_SCENE: 'dee01c7d-53e0-4f22-84c9-6249c530762c',
  Bulb_Diamond: '69241513-9b95-42aa-8cc5-41a27ea5f245',
  'Tube Cage': '30221ceb-e09b-4f3d-93a4-ccca7f2ea642',
  'Round Cage': '8fb4a437-32e2-414a-bd07-3ca2dcb875cf',
  'Net Shade': '326e8abf-b44f-4778-a2b5-86fbba06703d',
  'Diamond Cage': '5e87b045-9020-4461-8182-16d1636eebb2',
  'Bulb_3" Globe': '43c2a103-0cb3-4c0b-a7c6-9ff520eb162c',
  'Glass Cone Shade': '43c755b5-28db-48d1-a291-833f6514acc0',
  'Glass Cylinder Shade': 'e6e44754-a938-46d7-b848-872da89dc0b6',
  Bulb_Victorian: 'a23f87d9-2c6b-4ebc-bd28-82311ba38abb',
  Rope_Curve_Thick_M: '91fd44b4-815a-48d3-836b-5a992ab56b8b',
  Rope_Curve_Twisted_S: 'ca882439-0b9c-41d5-978d-b0f03de03e44',
  Rope_Curve_Thick_S: '7ad23c72-4685-46ab-94ca-45d4d1749235',
  'Glass Globe Shade': 'd16862a8-40d4-491e-99a8-911568ae868f',
  Rope_Curve_Twisted_M: 'e6a7148f-0ea6-472e-a16c-d1a1812c94fb',
  'Round Cage big': '0df612ca-ce00-4c2d-9504-993b711a0e2a',
  Bulb_A15: 'f7a54092-b77b-4c0d-bc61-45cfd7e2988e',
  'Flat Shade': '389e62a7-1c03-4983-8469-8d2ac5c2e2bd',
  Grip: '5e60e5d0-37ac-44c1-b45a-ec11e4316cbc',
  Hook_round: 'fe3aaeb0-7037-4e13-b23a-96240b0fd819',
  Hook_thick: 'b6f3f241-2d5b-47b2-ae4b-f9f9999a8a95',
  Rope_Curve_Round_L: '9f2f4234-2a53-490e-8758-bcb40319b1af',
  'Rope_Curve_Chain_S_4 Inch':'724a23c3-12fd-4820-ba77-7a0f887842b0',
  'Rope_Curve_Chain_S_1 Inch':'0aa4495e-7f4b-47fd-a69a-f6faddef7d28',
  Rope_Curve_Round_S: '102e2ae2-3ab1-4ace-95ee-91dfbeab8fcd',
  'Rope_Curve_Chain_M_4 Inch':'3b68ef17-e02d-4ef4-afdf-095e4b696b88',
  'Rope_Curve_Chain_M_1 Inch':'a5704a13-083e-425a-b156-689e62aa1e2b',
  Rope_Curve_Twisted_L: '0501ea62-5842-4115-89cf-86d90d40c77b',
  'Glass Bell Shade': 'f5ff715c-90ba-43a4-9662-1d312dfba2c4',
  Socket_Vintage: '0e4355c9-7f41-4802-808a-832f1af8ca28',
  Socket_Modern: 'afb48450-8913-443f-8273-df135d88393d',
  Socket_Threaded: '34887fbf-5c65-445a-8457-01c4a0f9b276',
  Socket_Keyed: '47acf97f-a746-4022-a3db-8c2ed807c0ee',
  'Socket_Modern Threaded': '2e8b18af-496b-4cad-87a1-5fe5e3a56e97',
  'Shade Fitter': '655783dc-a699-413d-962c-9a500a7d528e',
  Rope_Curve_Round_M: '62677bc0-9837-4c81-bad8-2a4007e88bb4',
  Hook_curved: '60bdf7e6-b8ef-41c8-b05c-4c9889e70958',
  'Ruffle Shade': 'f655df18-6fb5-4f34-af7b-b89163a9c0ea',
  'Glass Cage Shade': '3542c658-e1e3-4c85-819f-0798cd7368bf',
  'Bulb_4" Tube': '86d4b266-3e50-4900-99d7-c31341b72e2c',
  'Hinge Cage': 'b81d94d1-df79-41c7-8e1a-aade31ef78ad',
  'Radio Cage': '0cce406f-6bdb-4145-aa6f-604194444030',
  'Bulb_5" Globe': '26831b8c-4aa4-4a85-9b98-86034d73101e',
  'Cone Shade': '8861bc3f-87db-453d-9e81-1db12566f0ac',
  'Hinge Cage big': '04a574df-5d67-4efb-aa49-27619faaa820',
  'Bulb_7" Tube': '60ed5e36-fce4-4ec1-94fa-45af8b7dfad4',
  Large_Base: 'c2f4689f-9207-4c2a-86a1-c7ab323036d6',
  Bulb_Radio: 'cd564948-0927-445b-8012-b6f8f011d5c3',
  'Bulb_Dipped 5" Globe': 'bf87f14c-48d2-46ac-abf8-57515430205f',
  Bulb_None: 'feb13bc2-545f-4a59-a4b0-88d2efa8c297',
  Extras_None: '496da5da-9a81-487c-bd58-4855677192f0',
  'Glass Dome Shade': '1c41b23c-3050-4746-b27f-4e54e4a36a19',
  Bulb_Edison: 'c10f7cb9-6f33-4f63-9172-4caa6005edb5',
  Rope_Curve_Thick_L: 'd3fed03b-cd24-41a4-a02e-1f1654ce6c18',
  'Radio Cage big': 'bfee511e-03fa-4adc-9955-1ef72fba250b',
  'Straight Rope Round': '73d947b5-d909-44a2-a5b8-53a91b070b7f',
  'Straight Rope Thick': '88940e19-0bab-4cc6-8209-c123e679fc09',
  'Straight Rope Twisted': 'f5fe37f1-8edd-4cdb-b971-ad4dc66b4ce5',
  'Straight Rope Chain 4 Inch': '8774645e-93f4-4e7a-9956-4a0e2b83e45d',
  'Straight Rope Chain 1 Inch': '68e1c3d3-d873-4c0b-9972-72489bcf3b78',
  'Straight Rope Rigid Stem': 'e2fbcc8d-4af0-4b83-814d-51c7af545bc4',
  Small_Base: '0bd0432b-8557-41f6-a935-19f7818b6c1c',
  Socket_Wood: '2b803664-f6f0-4312-862e-b18f29a73987',
  Hook_silicone: 'ec4db4eb-927b-4624-8e9b-2a5d240e0342',
  'Flare Cage': 'ec0ac0f9-16c4-45d4-ac30-37f4c7fd4edc',
  'Octagon Cage': '2998730f-3ac3-4881-a5f7-c0e1ac053316',
  Rope_tie: '812c62d7-fbf2-426c-b7a1-a825f932cfed',
  Chain_grip:'11db095d-f255-49e6-a317-f9f38ec80ac7',
  Chain_grip_top:'29c0af2d-1f18-4240-9910-393489011780',
  '5" Drum Shade':'fe538d61-75bc-4556-8978-9e2c6d75988f',
  '12" Drum Shade':'3dbd8972-554d-4408-af51-168e44e3487b',
  'Small Flat Shade':'f7489e0b-a63a-4bda-a863-62c856860a9b',
  'Glass Small Flat Shade':'f7489e0b-a63a-4bda-a863-62c856860a9b',
  '16" Industrial Shade':'5f8d8e31-bb44-402d-9ccf-210a0f82d3fd',
  'Felt Cone Shade':'1c3e20b5-e302-4089-bc8b-eb5e9d3446ea',
  'Felt 10" Dome Shade':'05bd6ecf-9e4b-4a43-8e21-677b378924ce',
  'Felt 20" Flat Shade':'3d09be84-5e37-429b-a2ec-f716c8a2c9ea',
  'Felt 16" Dome Shade':'7b3f594c-3dd3-47e3-842e-f73cbcf065b1',
  'Bulb_XL Beehive':'01442bec-c39e-472d-839e-e0efcd75ea95',
  'Bulb_XL Pear':'4dacfce0-d4d9-49e6-9ec0-8109f9fcac29',
  'Bulb_XL UFO':'1efc75fb-b778-43f0-982f-d3f9d1e53658',
  'Bulb_XL 11" Tube':'831a53d0-f383-496d-be28-66bc0ca8d354',
  'Bulb_XL 8" Globe':'a65319a3-0b44-4b29-bd23-5424e63cc247',
  'Bulb_XL Oval':'0c4e88be-b8dd-44a1-8739-ac7bf9f6dc2e',
  'Bulb_XL Turret':'e6b62d48-b91f-4549-8b92-c938ba12dfaf',
  'Bulb_XL Teardrop':'24faf1a5-8730-4c4d-b1c1-d597a731083f',
  'Bulb_XL Uneven Bottle':'097e94f4-2395-4ad4-97c2-454877852e7b',
  'Bulb_XL Mushroom':'cf9476b0-6b89-4f33-8551-ec6fb51cf596',
  'Bulb_XL Bottle':'6d8bd8d0-258a-442e-a880-7970730b6f62',
  'Bulb_XL Gourd':'3db900fd-28f9-4d60-bbbf-0b294abffa82',
  'Bulb_Uneven 5" Globe':'b4725046-3a5d-41b3-b993-4e156284e45f',
  'Bulb_XL Uneven Pear':'e43f4e45-367d-4c4c-8691-4d48f75d6c3b',
  'Bulb_XL Bubbles':'5d85fadf-80df-4acd-9b2a-b19658feee91',
  'Bulb_XL Antler':'66c05ada-9579-489a-8154-3c1c0b90fa0a',
  'Bulb_XL Lined Teardrop':'eebaf49e-6c25-4ab4-868b-c25908b56cfa',
  'Bulb_Geoglobe':'23a15697-036a-4ba8-8909-010b42e3b592',
  'Bulb_2" Globe':'e2f58e0a-0dc9-4625-9cdd-4c56f5364f03',
  'Hinge Arm Sconce':'60d80e14-78c7-4e03-b344-95b2c113c8a3',
  '12" Dome Shade':'b7e6af2d-82db-475a-ac62-f2a0562c38d2',
  '5" Dome Shade': 'aece4221-8c19-4a3b-8761-b34a153838cf',
  '18" Dome Shade':'c5e036d0-13e3-4b65-9681-92259d29a1c3',
  '24" Dome Shade':'03aa2c2b-f1d7-40ea-b4a3-d1621b9b2443',
  'Dining Table - 6 x 4': 'ec1dba52-d57a-4a77-a6f6-fc7e443eb353',
  'Living Room' : 'c66994d1-b83d-41d1-a9d3-ee56331ed8f2',
  'Dining Table - 8 x 4': 'a5682d3c-6a6c-4c95-896e-e9890a263ca4',
  'Living Room - 2'  :'feaa1a3b-aadc-4414-a047-6d3251d8a90e',
  'Kitchen Island - 1'  :'d3de1ab3-5f06-42d6-91ea-1ead0ce5b761',
  'Stem - 3"' : 'fe2974e6-63ef-4118-a2d6-da295810b460',
  'Stem - 5"' : '52c45cc0-f263-4750-8004-f30c06be835e',
  'Stem - 7"' : '56822287-3019-40cf-adc4-0deb8a51281b',
  '10" Neckless Shade': '667e5ca4-2711-476f-934e-b44eb4a107b0',
 '12" Neckless Shade': 'e330ca1f-930a-4d3b-85a0-ba82dc3e30f6',
 '14" Neckless Shade': '2b692fab-b3e3-44d5-bc99-1352347c61b9',
 '16" Neckless Shade': 'c948d769-f888-49ff-900d-8b9b7a4a082f',
 '24" Disk Shade': '1e9bc415-4c19-4424-9a68-c632e9dd4787',
 '12" Disk Shade': '69e621b9-3973-492c-bce0-6cd6da5c15e9',
 '8" Disk Shade': '70a0a736-d875-469a-842d-2bf0688ce3e6',
 '5" Disk Shade': 'd040ed5b-e5e7-4ed6-8636-8d44c3b15c38',
 '20" Deep Dome Shade': '46bfc6c6-a7ae-4793-8807-303151f4ee41',
 '18" Woven Dome Shade': '7645bdf8-bf7c-4fd3-9794-542b4b043450',
 '18" Woven Basket Shade': 'f2fcea6b-09c6-4b10-8cea-2723be94d38e',
 '24" Woven Dome Shade': '6db655bd-96df-4379-9ab2-14388821ef8e',
 '18" Woven Round Shade': 'e1de1720-53bf-41e6-bec2-d240e2828ace',
 '24" Woven Basket Shade': '361c5c00-b0c5-4f24-8df8-6d5e964c443f',
 '30" Woven Basket Shade': '9cc11aa8-2d35-42b6-8ea4-a56a2f5efc13',
 '36" Woven Basket Shade': '1ad9e52f-6759-4bf4-a495-619eb2a87414',
 '24" Woven Round Shade': 'd99b5b4c-4254-4070-85da-9036d9e8888d', 
 '8" Neckless Shade': 'be910f6c-7c42-4e0a-b42e-17759ea1ece3',
 
 
};
const fetchedScenes = {};
const Colors = {
  Brass: '#c8b96f',
  Nickel: '#afafaf',
  Copper: '#E8B298',
  Steel: '#737373',
  'Terracotta Tweed': '{ r: 0.886, g: 0.447, b: 0.357 }',
  Aqua: { r: 0.0, g: 1.0, b: 1.0 },
  Turquoise: { r: 0.031, g: 0.91, b: 0.871 },
  Bronze: '#544947',
  Chrome: '#D9DDE0',
  Beige: '#e8dcb5',
  Champagne: '#f5eaba',
  Blush: '#ffceb0',
  Gold: '#d4ab0b',
  'Dipped Gold': '#ccb045',
  Bubblegum: '#ee9a9a',
  Tan: '#dbc993',
  Pine: '#203c33',
  Navy: '#1e2960',
  Red: '#ed1c24',
  Teal: '#04584b',
  Orange: '#f67430',
  Berry: '#7b213b',
  Wine: '#942537',
  Black: '#0d0d0d',
  Graphite: '#454545',
  Green: '#048f4c',
  Lime: '#71bf20',
  Grey: '#a4a4a4',
  Eucalyptus: '#cee3cc',
  Mauve: '#866f7c',
  Taupe: '#695152',
  Olive: '#9f9a61',
  Tuquoise: '#3bafb8',
  White: '#ffffff',
  Rust: '#994800',
  Army: '#3b5542',
  Yellow: '#fff200',
  Purple: '#5b3195',
  Pink: '#ec008c',
  Mint: '#94c6a0',
  Brown: '#573324',
  Cobalt: '#1836a6',
  Blue: '#1836a6',
  Coral: '#ff9689',
  Antique: '#b6ac8b',
  Manila: '#836139',
  Cotton: '#e1e1e1',
  Marble: '#ffffff',
  Slate:"#4b4d52",
  Evergreen:"#064b43",
  Crimson:"#8b2831",
  'Chain Antique': "#8d8158",
};
const CEILING_PLATE_COLORS = {
  White: '#ebeded',
  Brass: '#cfbe69',
};
const SIN60X = function(x) {
  return Math.sin((Math.PI / 3) * x);
};
const COS60X = function(x) {
  return Math.cos((Math.PI / 3) * x);
};
const SINNX = function(n, x) {
  return Math.sin(((2 * Math.PI) / n) * x);
};
const COSNX = function(n, x) {
  return Math.cos(((2 * Math.PI) / n) * x);
};
const VECTORS = {
  Even: {
    //x,y refers to position of bulb from top view
    3: [
      { x: 0, y: 2.65 / SIN60X(1) },
      { x: 2.65, y: -2.65 / Math.tan(Math.PI / 3) },
      { x: -2.65, y: -2.65 / Math.tan(Math.PI / 3) },
    ],
    5: [
      { x: 0, y: 2.65 / 0.5878 },
      {
        x: (2.65 / 0.5878) * Math.sin((2 * Math.PI) / 5),
        y: (2.65 / 0.5878) * Math.cos((2 * Math.PI) / 5),
      },
      {
        x: (2.65 / 0.5878) * Math.sin(((2 * Math.PI) / 5) * 2),
        y: (2.65 / 0.5878) * Math.cos(((2 * Math.PI) / 5) * 2),
      },
      {
        x: (2.65 / 0.5878) * Math.sin(((2 * Math.PI) / 5) * 3),
        y: (2.65 / 0.5878) * Math.cos(((2 * Math.PI) / 5) * 3),
      },
      {
        x: (2.65 / 0.5878) * Math.sin(((2 * Math.PI) / 5) * 4),
        y: (2.65 / 0.5878) * Math.cos(((2 * Math.PI) / 5) * 4),
      },
    ],
    7: [
      { x: 0, y: 0 },
      { x: 0, y: 5.4 },
      { x: 5.45 * SIN60X(1), y: 5.45 * COS60X(1) },
      { x: 5.45 * SIN60X(2), y: 5.45 * COS60X(2) },
      { x: 0, y: -5.4 },
      { x: 5.45 * SIN60X(4), y: 5.45 * COS60X(4) },
      { x: 5.45 * SIN60X(5), y: 5.45 * COS60X(5) },
    ],
    9: [
      { x: 0, y: 0 },
      { x: 0, y: 5.4 },
      { x: 5.45 * SIN60X(1), y: 5.45 * COS60X(1) },
      { x: 5.3 * SIN60X(1) * 2, y: 0 },
      { x: 5.45 * SIN60X(2), y: 5.45 * COS60X(2) },
      { x: 0, y: -5.4 },
      { x: 5.45 * SIN60X(4), y: 5.45 * COS60X(4) },
      { x: -5.3 * SIN60X(1) * 2, y: 0 },
      { x: 5.45 * SIN60X(5), y: 5.45 * COS60X(5) },
    ],
    14: [
      { x: 0, y: 2.7 * 3 },
      { x: 0, y: 2.7 },
      { x: 5.4 * SIN60X(1), y: 5.4 },
      { x: 5.35 * SIN60X(1) * 2, y: 2.7 },
      { x: 5.4 * SIN60X(1), y: 0 },
      { x: 5.35 * SIN60X(1) * 2, y: -2.7 },
      { x: 5.4 * SIN60X(1), y: -5.4 },
      { x: 0, y: -2.7 * 3 },
      { x: 0, y: -2.7 },
      { x: -5.4 * SIN60X(1), y: -5.4 },
      { x: -5.35 * SIN60X(1) * 2, y: -2.7 },
      { x: -5.4 * SIN60X(1), y: 0 },
      { x: -5.35 * SIN60X(1) * 2, y: 2.7 },
      { x: -5.4 * SIN60X(1), y: 5.4 },
    ],
    19: [
      { x: 0, y: 2.7 * 4 },
      { x: 5.4 * SIN60X(1), y: 2.7 * 4 - 5.4 * COS60X(1) },
      { x: 5.4 / Math.tan(Math.PI / 6), y: 5.4 },
      { x: 5.4 / Math.tan(Math.PI / 6), y: 0 },
      { x: 5.4 / Math.tan(Math.PI / 6), y: -5.4 },
      { x: 5.4 * SIN60X(1), y: -2.7 * 4 + 5.4 * COS60X(1) },
      { x: 0, y: -2.7 * 4 },
      { x: -5.4 * SIN60X(1), y: -2.7 * 4 + 5.4 * COS60X(1) },
      { x: -5.4 / Math.tan(Math.PI / 6), y: -2.7 * 2 },
      { x: -5.4 / Math.tan(Math.PI / 6), y: 0 },
      { x: -5.4 / Math.tan(Math.PI / 6), y: 5.4 },
      { x: -5.4 * SIN60X(1), y: 2.7 * 4 - 5.4 * COS60X(1) }, //11
      { x: 0, y: 0 },
      { x: 0, y: 5.45 },
      { x: 5.45 * SIN60X(1), y: 5.45 * COS60X(1) },
      { x: 5.45 * SIN60X(2), y: 5.45 * COS60X(2) },
      { x: 0, y: -5.45 },
      { x: 5.45 * SIN60X(4), y: 5.45 * COS60X(4) },
      { x: 5.45 * SIN60X(5), y: 5.45 * COS60X(5) },
    ],
  },
  Staggered: {
    //x,y refers to position of bulb from top view
    3: [
      { x: -0.2, y: 2.4 / SIN60X(1) },
      { x: 2.3, y: -2.25 / Math.tan(Math.PI / 3) },
      { x: -2.25, y: -2.25 / Math.tan(Math.PI / 3) },
    ],
    5: [
      { x: -0.5, y: 2.45 / 0.5878 },
      {
        x: (2.4 / 0.5878) * Math.sin((2 * Math.PI) / 5),
        y: (2.4 / 0.5878) * Math.cos((2 * Math.PI) / 5),
      },
      {
        x: (2.4 / 0.5878) * Math.sin(((2 * Math.PI) / 5) * 2),
        y: (2.4 / 0.5878) * Math.cos(((2 * Math.PI) / 5) * 2),
      },
      {
        x: (2.4 / 0.5878) * Math.sin(((2 * Math.PI) / 5) * 3),
        y: (2.4 / 0.5878) * Math.cos(((2 * Math.PI) / 5) * 3),
      },
      {
        x: (2.4 / 0.5878) * Math.sin(((2 * Math.PI) / 5) * 4),
        y: (2.4 / 0.5878) * Math.cos(((2 * Math.PI) / 5) * 4),
      },
    ],
    7: [
      { x: -0.5, y: 5.6 },
      { x: 5.45 * SIN60X(1), y: 5.45 * COS60X(1) },
      { x: 5.45 * SIN60X(2), y: 5.45 * COS60X(2) },
      { x: 0, y: -5.4 },
      { x: 5 * SIN60X(4), y: 5 * COS60X(4) },
      { x: 5 * SIN60X(5), y: 5 * COS60X(5) },
      { x: 0, y: -1.0 },
    ],
    9: [
      { x: -0.7, y: 7 },
      {
        x: 7.5 * SINNX(9, 1),
        y: 7.5 * COSNX(9, 1),
      },
      {
        x: 7.5 * SINNX(9, 2),
        y: 7.5 * COSNX(9, 2),
      },
      {
        x: 7.5 * SINNX(9, 3),
        y: 7.5 * COSNX(9, 3),
      },
      {
        x: 7 * SINNX(9, 4),
        y: 7 * COSNX(9, 4),
      },
      {
        x: 7 * SINNX(9, 5),
        y: 7 * COSNX(9, 5),
      },
      {
        x: 7 * SINNX(9, 6),
        y: 7 * COSNX(9, 6),
      },
      {
        x: 7 * SINNX(9, 7),
        y: 7 * COSNX(9, 7),
      },
      {
        x: 6 * SINNX(9, 8),
        y: 6 * COSNX(9, 8),
      },
    ],
    14: [
      { x: -3.2, y: 9.5 },
      { x: 10.5 * SINNX(13, 1) - 2.5, y: 10.5 * COSNX(13, 1) },
      { x: 10.5 * SINNX(13, 2) - 1.5, y: 10.5 * COSNX(13, 2) },
      { x: 10.5 * SINNX(13, 3), y: 10.5 * COSNX(13, 3) },
      { x: 10.5 * SINNX(13, 4), y: 10.5 * COSNX(13, 4) },
      { x: 10 * SINNX(13, 5), y: 10 * COSNX(13, 5) },
      { x: 10 * SINNX(13, 6), y: 10 * COSNX(13, 6) },
      { x: 9.5 * SINNX(13, 7), y: 9.5 * COSNX(13, 7) },
      { x: 9 * SINNX(13, 8), y: 9 * COSNX(13, 8) },
      { x: 9 * SINNX(13, 9), y: 9 * COSNX(13, 9) },
      { x: 8 * SINNX(13, 10), y: 8 * COSNX(13, 10) },
      { x: 7 * SINNX(13, 11), y: 7 * COSNX(13, 11) },
      { x: 4.2 * SINNX(13, 12) + 1, y: 5.3 * COSNX(13, 12) },
      { x: 1.0, y: -0.5 },
    ],
    19: [
      { x: -3, y: 9 },
      { x: 9.9 * SINNX(12, 1) - 2.5, y: 9.9 * COSNX(12, 1) },
      { x: 9.9 * SINNX(12, 2) - 1.7, y: 9.9 * COSNX(12, 2) },
      { x: 9.9 * SINNX(12, 3), y: 9.9 * COSNX(12, 3) },
      { x: 9.9 * SINNX(12, 4), y: 9.9 * COSNX(12, 4) },
      { x: 9.9 * SINNX(12, 5), y: 9.9 * COSNX(12, 5) },
      { x: 9 * SINNX(12, 6), y: 9 * COSNX(12, 6) },
      { x: 9 * SINNX(12, 7), y: 9 * COSNX(12, 7) },
      { x: 9 * SINNX(12, 8), y: 9 * COSNX(12, 8) },
      { x: 9 * SINNX(12, 9), y: 9 * COSNX(12, 9) },
      { x: 8 * SINNX(12, 10), y: 8 * COSNX(12, 10) },
      { x: 8 * SINNX(12, 11), y: 8 * COSNX(12, 11) }, //11
      { x: 0, y: 5.45 },
      { x: 5.45 * SIN60X(1), y: 5.45 * COS60X(1) },
      { x: 5.45 * SIN60X(2), y: 5.45 * COS60X(2) },
      { x: 0, y: -5.45 },
      { x: 5.45 * SIN60X(4), y: 5.45 * COS60X(4) },
      { x: 4.45 * SIN60X(5), y: 4.45 * COS60X(5) },
      { x: 0, y: 0 },
    ],
  },
  Grape: {
    //x,y refers to ROTATION of bulb and socket
    3: [{ x: -4.2, y: 0 }, { x: -8, y: 240 }, { x: -6.9, y: 117 }],
    5: [
      { x: 1.8, y: 0 },
      { x: -10.5, y: -41 },
      { x: -10.3, y: -144 },
      { x: -12.7, y: -215 },
      { x: -11.6, y: -288 },
    ],
    7: [
      { x: 2.8, y: 0 },
      { x: -12.5, y: -28 },
      { x: -13.2, y: -95 },
      { x: -14.7, y: -151 },
      { x: -10.4, y: -213 },
      { x: -12.2, y: -288 },
      { x: -20.5, y: -325 },
    ],
    9: [
      { x: -11.7, y: -22 },
      { x: -27.7, y: -116 },
      { x: 0, y: 0 },
      { x: -11.2, y: -88.4 },
      { x: 8.5, y: -17 },
      { x: -18.6, y: -140 },
      { x: -19.7, y: -197.5 },
      { x: -17.8, y: -241.5 },
      { x: -8.8, y: -290 },
    ],
    14: [
      { x: 1.2, y: 0 },
      { x: -35, y: 5 },
      { x: -18.5, y: -23 },
      { x: -8, y: -45 },
      { x: -13, y: -100 },
      { x: -26.8, y: -148 },
      { x: -6.5, y: -150 },
      { x: -14.5, y: -194 },
      { x: -33, y: -217 },
      { x: -9.3, y: -232 },
      { x: -20, y: -230 },
      { x: -20, y: -280 },
      { x: -8.8, y: -319 },
      { x: -18, y: -331 },
    ],
    19: [
      { x: -36, y: 0 },
      { x: -21.5, y: -30 },
      { x: -23, y: -78 },
      { x: -21, y: -115 },
      { x: -8.2, y: -155 },
      { x: -31, y: -143 },
      { x: 25, y: 0 },
      { x: -19, y: -237 },
      { x: -10.2, y: -239 },
      { x: -17, y: -271 },
      { x: -30, y: -289 },
      { x: -8, y: -337 },
      { x: -19, y: -4 },
      { x: -9, y: -50 },
      { x: -17, y: -100 },
      { x: -20, y: -151 },
      { x: -17, y: -200 },
      { x: 0, y: 0 },
      { x: -20, y: -322 },
    ],
  },
};
const ROPE_LENGTH = {
  Swag: {
    4: {
      2: [4, 6],
      3: [4, 5, 6],
      5: [5, 4, 6, 5, 6],
      7: [5, 4, 6, 5, 4, 5, 6],
      9: [4, 5, 6, 4, 5, 6, 4, 5, 6],
      14: [4, 5, 6, 4, 5, 6, 4, 5, 6, 4, 5, 6, 5, 6],
      19: [4, 5, 6, 4, 5, 6, 4, 5, 6, 4, 5, 6, 4, 5, 6, 4, 5, 6, 5],
    },
    6: {
      2: [6, 10],
      3: [6, 8, 10],
      5: [8, 6, 10, 8, 10],
      7: [8, 6, 10, 8, 6, 8, 10],
      9: [6, 8, 10, 6, 8, 10, 6, 8, 10],
      14: [8, 6, 10, 8, 6, 10, 8, 6, 8, 10, 8, 6, 8, 10],
      19: [8, 10, 6, 8, 10, 6, 8, 10, 6, 8, 10, 6, 8, 10, 6, 8, 10, 8, 6],
    },
    10: {
      2: [10, 15],
      3: [10, 12, 15],
      5: [15, 12, 10, 15, 12],
      7: [12, 15, 10, 12, 15, 12, 10],
      9: [10, 12, 15, 10, 12, 15, 10, 12, 15],
      14: [12, 10, 15, 12, 10, 15, 12, 10, 12, 15, 12, 10, 12, 15],
      19: [
        13,
        15,
        10,
        13,
        15,
        10,
        13,
        15,
        10,
        13,
        15,
        10,
        13,
        15,
        10,
        13,
        15,
        13,
        10,
      ],
    },
  },
  Wood: {
    '35 x 9': {
      5: [30, 21, 30, 21, 30],
      7: [30, 15, 21, 30, 15, 21, 30],
    },
    '35 x 12': {
      5: [30, 21, 30, 21, 30],
      7: [30, 15, 21, 30, 15, 21, 30],
      10: [25, 21, 15, 30, 23, 18, 30, 23, 15, 25],
    },
    '35 x 18': {
      10: [21, 25, 15, 23, 30, 30, 18, 15, 25, 23],
      14: [19, 35, 39, 26, 17, 23, 35, 22, 26, 17, 30, 23, 35, 21],
      17: [19, 35, 39, 26, 17, 27, 35, 22, 16, 21, 31, 26, 18, 23, 28, 35, 21],
    },
    '47 x 9': {
      5: [30, 21, 30, 21, 30],
      7: [30, 15, 21, 30, 15, 21, 30],
    },
    '47 x 12': {
      5: [30, 21, 30, 21, 30],
      7: [30, 15, 21, 30, 15, 21, 30],
      10: [25, 21, 15, 30, 23, 18, 30, 23, 15, 25],
      14: [32, 20, 28, 17, 35, 26, 35, 18, 25, 32, 20, 23, 18, 35],
      17: [19, 35, 30, 26, 17, 27, 35, 22, 16, 21, 31, 26, 18, 23, 28, 30, 21],
    },
    '47 x 24': {
      10: [21, 25, 15, 23, 30, 30, 18, 15, 25, 23],
      14: [19, 35, 30, 26, 17, 23, 35, 22, 26, 17, 30, 23, 35, 27],
      17: [19, 35, 30, 26, 17, 27, 35, 22, 16, 21, 31, 26, 18, 23, 28, 35, 21],
    },
    '70 x 12': {
      5: [30, 21, 30, 21, 30],
      7: [30, 15, 21, 30, 15, 21, 30],
      10: [25, 21, 15, 30, 23, 18, 30, 23, 15, 25],
      14: [32, 20, 28, 17, 35, 26, 35, 18, 25, 32, 20, 23, 18, 35],
      17: [19, 35, 30, 26, 17, 27, 35, 22, 16, 21, 31, 26, 18, 23, 28, 35, 21],
    },
    '12 Round': {
      3: [21, 15, 30],
      5: [25, 15, 30, 18, 23],
      7: [32, 15, 27, 35, 18, 25, 22],
      10: [20, 15, 25, 30, 20, 35, 28, 30, 19, 23],
    },
    '16 Round': {
      5: [25, 15, 30, 18, 23],
    },
    '18 Round': {
      5: [25, 15, 30, 18, 23],
      7: [32, 15, 27, 35, 18, 25, 22],
      10: [20, 15, 25, 30, 20, 35, 28, 30, 19, 23],
    },
    '18 Square': {
      5: [25, 15, 30, 18, 23],
      7: [32, 15, 27, 35, 18, 25, 22],
      10: [20, 15, 25, 30, 20, 35, 28, 30, 19, 23],
    },
    '24 Round': {
      5: [25, 15, 30, 18, 23],
      7: [32, 15, 27, 35, 18, 25, 22],
      10: [20, 15, 25, 30, 20, 35, 28, 30, 19, 23],
      14: [28, 20, 15, 15, 30, 25, 23, 35, 30, 35, 18, 27, 20, 30],
      17: [28, 20, 15, 15, 25, 30, 23, 20, 28, 23, 35, 30, 35, 18, 27, 20, 30],
    },
    '33 Round': {
      5: [25, 15, 30, 18, 23],
      7: [32, 15, 27, 35, 18, 25, 22],
      10: [20, 15, 25, 30, 20, 35, 28, 30, 19, 23],
      14: [28, 20, 15, 15, 30, 25, 23, 35, 30, 35, 18, 27, 20, 30],
      17: [28, 20, 15, 15, 25, 30, 23, 20, 28, 23, 35, 30, 35, 18, 27, 20, 30],
    },
    '24 Square': {
      5: [25, 15, 30, 18, 23],
      7: [32, 15, 27, 35, 18, 25, 22],
      10: [20, 15, 25, 30, 20, 35, 28, 30, 19, 23],
      14: [28, 20, 15, 15, 30, 25, 23, 35, 30, 35, 18, 27, 20, 30],
      17: [28, 20, 15, 15, 25, 30, 23, 20, 28, 23, 35, 30, 35, 18, 27, 20, 30],
    },
    '48 Square': {
      5: [25, 15, 30, 18, 23],
      7: [32, 15, 27, 35, 18, 25, 22],
      9: [20, 15, 25, 30, 20, 35, 28, 30, 19],
      10: [20, 15, 25, 30, 20, 35, 28, 30, 19, 23],
      14: [28, 20, 15, 15, 30, 25, 23, 35, 30, 35, 18, 27, 20, 30],
      16: [28, 20, 15, 15, 25, 30, 23, 20, 28, 23, 35, 30, 35, 18, 27, 20],
      17: [28, 20, 15, 15, 25, 30, 23, 20, 28, 23, 35, 30, 35, 18, 27, 20, 30],
      25: [28, 20, 15, 15, 25, 30, 23, 20, 28, 23, 35, 30, 35, 18, 27, 20, 30, 23, 35, 30, 35, 18, 27, 20, 30],
    },
    '36 x 96': {
      18: [28, 20, 15, 15, 25, 30, 23, 20, 28, 23, 35, 30, 35, 18, 27, 20, 30,23],
      27: [28, 20, 15, 15, 25, 30, 23, 20, 28, 23, 35, 30, 35, 18, 27, 20, 30, 23, 35, 30, 35, 18, 27, 20, 30, 18, 27],
    },
  },
  'Medium Wood': {
  '35 x 9': {
    5: [48, 21, 48, 21, 48],
    7: [48, 15, 32, 48, 15, 32, 48],
  },
  '35 x 12': {
    5: [48, 21, 48, 21, 48],
    7: [48, 15, 32, 48, 15, 32, 48],
    10: [38, 48, 15, 48, 33, 23, 48, 33, 15, 38],
  },
  '35 x 18': {
    10: [29, 38, 15, 33, 48, 48, 23, 15, 38, 33],
    14: [20, 42, 48, 30, 17, 25, 42, 24, 30, 17, 35, 25, 42, 23],
    17: [20, 42, 48, 30, 17, 31, 42, 24, 16, 23, 37, 30, 19, 26, 33, 42, 23],
  },
  '47 x 9': {
    5: [48, 21, 48, 21, 48],
    7: [48, 15, 32, 48, 15, 32, 48],
  },
  '47 x 12': {
    5: [48, 21, 48, 21, 48],
    7: [48, 15, 32, 48, 15, 32, 48],
    10: [37, 28, 15, 48, 33, 22, 48, 33, 15, 37],
    14: [43, 22, 36, 17, 48, 33, 48, 19, 31, 43, 22, 27, 19, 48],
    17: [21, 48, 40, 33, 18, 35, 48, 26, 16, 24, 41, 33, 19, 28, 36, 41, 24],
  },
  '47 x 24': {
    10: [28, 37, 15, 33, 48, 48, 22, 15, 37, 33],
    14: [20, 48, 39, 33, 17, 27, 48, 26, 33, 17, 39, 27, 48, 34],
    17: [21, 48, 39, 33, 18, 35, 48, 26, 16, 24, 41, 33, 19, 28, 36, 48, 24],
  },
  '70 x 12': {
    5: [48, 21, 48, 21, 48],
    7: [48, 15, 32, 48, 15, 32, 48],
    10: [37, 28, 15, 48, 33, 22, 48, 33, 15, 37],
    14: [43, 22, 36, 17, 48, 33, 48, 19, 31, 43, 22, 27, 19, 48],
    17: [21, 48, 40, 33, 18, 35, 48, 26, 16, 24, 41, 33, 19, 28, 36, 48, 24],
  },
  '12 Round': {
    3: [29, 15, 48],
    5: [37, 15, 48, 22, 33],
    7: [43, 15, 35, 48, 20, 32, 27],
    10: [23, 15, 32, 48, 23, 40, 36, 40, 22, 28],
  },
  '16 Round': {
    5: [37, 15, 48, 22, 33],
  },
  '18 Round': {
    5: [37, 15, 48, 22, 33],
    7: [43, 15, 35, 48, 20, 32, 27],
    10: [23, 15, 32, 48, 23, 40, 36, 40, 22, 28],
  },
  '18 Square': {
    5: [37, 15, 48, 22, 33],
    7: [43, 15, 35, 48, 20, 32, 27],
    10: [23, 15, 32, 40, 23, 48, 36, 40, 22, 28],
  },
  '24 Round': {
    5: [37, 15, 48, 22, 33],
    7: [43, 15, 35, 48, 20, 32, 27],
    10: [23, 15, 32, 40, 23, 48, 36, 40, 22, 28],
    14: [36, 23, 15, 15, 40, 32, 28, 48, 40, 48, 20, 35, 23, 40],
    17: [36, 23, 15, 15, 32, 40, 28, 23, 36, 28, 48, 40, 48, 20, 35, 23, 40],
  },
  '33 Round': {
    5: [37, 15, 48, 22, 33],
    7: [43, 15, 35, 48, 20, 32, 27],
    10: [23, 15, 32, 40, 23, 48, 36, 40, 22, 28],
    14: [36, 23, 15, 15, 40, 32, 28, 48, 40, 48, 20, 35, 23, 40],
    17: [36, 23, 15, 15, 32, 40, 28, 23, 36, 28, 48, 40, 48, 20, 35, 23, 40],
  },
  '24 Square': {
    5: [37, 15, 48, 22, 33],
    7: [43, 15, 35, 48, 20, 32, 27],
    10: [23, 15, 32, 40, 23, 48, 36, 40, 22, 28],
    14: [36, 23, 15, 15, 40, 32, 28, 48, 40, 48, 20, 35, 23, 40],
    17: [36, 23, 15, 15, 32, 40, 28, 23, 36, 28, 48, 40, 48, 20, 35, 23, 40],
  },
  '48 Square': {
    5: [37, 15, 48, 22, 33],
    7: [43, 15, 35, 48, 20, 32, 27],
    9: [23, 15, 32, 40, 23, 48, 36, 40, 22],
    10: [23, 15, 32, 40, 23, 48, 36, 40, 22, 28],
    14: [36, 23, 15, 15, 40, 32, 28, 48, 40, 48, 20, 35, 23, 40],
    16: [36, 23, 15, 15, 32, 40, 28, 23, 36, 28, 48, 40, 48, 20, 35, 23],
    17: [36, 23, 15, 15, 32, 40, 28, 23, 36, 28, 48, 40, 48, 20, 35, 23, 40],
    25: [36, 23, 15, 15, 32, 40, 28, 23, 36, 28, 48, 40, 48, 20, 35, 23, 40, 28, 48, 40, 48, 20, 35, 23, 40],
  },
  '36 x 96': {
    18: [36, 23, 15, 15, 32, 40, 28, 23, 36, 28, 48, 40, 48, 20, 35, 23, 40,28],
    27: [36, 23, 15, 15, 32, 40, 28, 23, 36, 28, 48, 40, 48, 20, 35, 23, 40, 28, 48, 40, 48, 20, 35, 23, 40, 23, 35],
  },
},
'Long Wood': {
  '35 x 9': {
    5: [72, 36, 72, 36, 72],
    7: [72, 15, 44, 72, 15, 44, 72],
  },
  '35 x 12': {
    5: [72, 36, 72, 36, 72],
    7: [72, 15, 44, 72, 15, 44, 72],
    10: [55, 29, 15, 72, 46, 29, 72, 46, 15, 55],
  },
  '35 x 18': {
    10: [39, 60, 15, 46, 72, 55, 29, 15, 55, 46],
    14: [26, 49, 61, 40, 17, 32, 72, 54, 40, 17, 61, 44, 61, 28],
    17: [27, 53, 62, 41, 18, 42, 72, 54, 16, 28, 53, 20, 53, 34, 46, 62, 28],
  },
  '47 x 9': {
    5: [72, 36, 72, 36, 72],
    7: [72, 15, 44, 72, 15, 44, 72],
  },
  '47 x 12': {
   5: [72, 36, 72, 36, 72],
    7: [72, 15, 44, 72, 15, 44, 72],
    10: [53, 37, 15, 72, 46, 27, 72, 46, 15, 53],
    14: [63, 26, 51, 17, 72, 42, 72, 35, 42, 63, 51, 35, 21, 72],
    17: [25, 72, 58, 46, 20, 49, 72, 34, 16, 30, 60, 46, 21, 37, 51, 62, 30],
  },
  '47 x 24': {
    10: [38, 53, 15, 45, 72, 72, 26, 15, 53, 45],
    14: [23, 48, 62, 45, 17, 35, 72, 32, 45, 17, 57, 35, 48, 66],
    17: [25, 72, 57, 45, 19, 48, 72, 34, 16, 31, 60, 58, 34, 37, 51, 66, 31],
  },
  '70 x 12': {
   5: [72, 36, 72, 36, 72],
    7: [72, 15, 44, 72, 15, 44, 72],
    10: [53, 37, 15, 72, 46, 27, 72, 46, 15, 53],
    14: [63, 26, 51, 17, 72, 55, 72, 21, 42, 63, 51, 35, 21, 72],
    17: [25, 72, 58, 46, 20, 49, 72, 34, 16, 30, 58, 46, 21, 37, 51, 60, 30],
  },
  '12 Round': {
    3: [42, 15, 72],
    5: [53, 15, 72, 27, 46],
    7: [15, 24, 50, 72, 62, 44, 36],
    10: [29, 15, 44, 58, 72, 30, 51, 58, 27, 37],
  },
  '16 Round': {
    5: [53, 15, 72, 27, 46],
  },
  '18 Round': {
    5: [53, 15, 72, 27, 46],
    7: [15, 24, 50, 72, 62, 44, 36],
    10: [29, 15, 44, 58, 72, 30, 51, 58, 27, 37],
  },
  '18 Square': {
    5: [53, 15, 72, 27, 46],
    7: [62, 15, 50, 72, 24, 44, 36],
    10: [29, 15, 44, 58, 72, 30, 51, 58, 27, 37],
  },
  '24 Round': {
    5: [53, 15, 72, 27, 46],
    7: [62, 36, 50, 72, 24, 44, 15],
    10: [29, 15, 44, 58, 30, 51, 72, 58, 27, 37],
    14: [51, 29, 24, 15, 58, 44, 37, 72, 58, 72, 24, 50, 29, 58],
    17: [52, 29, 15, 15, 44, 58, 38, 29, 58, 38, 72, 52, 72, 24, 49, 29, 38],
  },
  '33 Round': {
    5: [53, 15, 72, 27, 46],
    7: [62, 36, 50, 72, 24, 44, 15],
    10: [29, 15, 44, 58, 30, 51, 72, 58, 27, 37],
    14: [51, 29, 24, 15, 58, 44, 37, 72, 58, 72, 24, 50, 29, 58],
    17: [52, 29, 15, 15, 44, 58, 38, 29, 58, 38, 72, 52, 72, 24, 49, 29, 38],
  },
  '24 Square': {
    5: [53, 15, 72, 27, 46],
    7: [62, 36, 50, 72, 24, 44, 15],
    10: [29, 15, 44, 58, 30, 72, 51, 58, 27, 37],
    14: [51, 29, 24, 15, 58, 44, 37, 72, 58, 72, 24, 50, 29, 58],
    17: [52, 29, 15, 15, 44, 58, 38, 29, 58, 38, 72, 52, 72, 24, 49, 29, 38],
  },
  '48 Square': {
    5: [53, 15, 72, 27, 46],
    7: [62, 15, 50, 72, 24, 44, 36],
    9: [29, 15, 44, 58, 30, 72, 51, 58, 27],
    10: [29, 15, 44, 58, 30, 72, 51, 58, 27, 37],
    14: [51, 29, 44, 40, 58, 15, 37, 72, 58, 72, 24, 50, 29, 58],
    16: [52, 29, 44, 40, 58, 58, 38, 29, 52, 38, 72, 72, 58, 24, 49, 29],
    17: [52, 29, 44, 40, 58, 58, 38, 29, 52, 38, 72, 72, 58, 24, 49, 29, 15],
    25: [52, 29, 44, 40, 58, 58, 38, 29, 52, 38, 72, 72, 58, 24, 49, 29, 15, 38, 72, 72, 58, 24, 49, 29, 15],
  },
  '36 x 96': {
    18: [52, 29, 44, 40, 58, 58, 38, 29, 52, 38, 72, 72, 58, 24, 49, 29, 15, 38],
    27: [52, 29, 44, 40, 58, 58, 38, 29, 52, 38, 72, 72, 58, 24, 49, 29, 15, 38, 72, 72, 58, 24, 49, 29, 15, 38, 52],
  },
},
'36-120 Wood': {
  '35 x 9': {
    5: [99, 36, 120, 57, 78],
    7: [36, 50, 92, 120, 106, 78, 64],
  },
  '35 x 12': {
    5: [99, 36, 120, 57, 78],
    7: [36, 50, 92, 120, 106, 78, 64],
    10: [54, 36, 82, 101, 120, 63, 91, 110, 45, 73],
  },
  '35 x 18': {
    10: [54, 36, 82, 101, 120, 63, 91, 110, 45, 73],
    14: [49, 114, 94, 68, 36, 120, 81, 101, 62, 42, 88, 75, 108, 55],
    17: [51, 88, 114, 72, 41, 103, 120, 98, 36, 56, 82, 46, 93, 66, 77, 108, 61],
  },
  '47 x 9': {
    5: [99, 36, 120, 57, 78],
    7: [36, 50, 92, 120, 106, 78, 64],
  },
  '47 x 12': {
    5: [99, 36, 120, 57, 78],
    7: [36, 50, 92, 120, 106, 78, 64],
    10: [54, 36, 82, 101, 120, 63, 91, 110, 45, 73],
    14: [49, 114, 94, 68, 36, 120, 81, 101, 62, 42, 88, 75, 108, 55],
    17: [51, 88, 114, 72, 41, 103, 120, 98, 36, 56, 82, 46, 93, 66, 77, 108, 61],
  },
  '47 x 24': {
    10: [54, 36, 82, 101, 120, 63, 91, 110, 45, 73],
    14: [49, 114, 94, 68, 36, 120, 81, 101, 62, 42, 88, 75, 108, 55],
    17: [51, 88, 114, 72, 41, 103, 120, 98, 36, 56, 82, 46, 93, 66, 77, 108, 61],
  },
  '70 x 12': {
    5: [99, 36, 120, 57, 78],
    7: [36, 50, 92, 120, 106, 78, 64],
    10: [54, 36, 82, 101, 120, 63, 91, 110, 45, 73],
    14: [49, 114, 94, 68, 36, 120, 81, 101, 62, 42, 88, 75, 108, 55],
    17: [51, 88, 114, 72, 41, 103, 120, 98, 36, 56, 82, 46, 93, 66, 77, 108, 61],
  },
  '12 Round': {
    3: [78, 36, 120],
    5: [99, 36, 120, 57, 78],
    7: [36, 50, 92, 120, 106, 78, 64],
    10: [54, 36, 82, 101, 91, 63, 120, 110, 45, 73],
  },
  '16 Round': {
    5: [99, 36, 120, 57, 78],
  },
  '18 Round': {
    5: [99, 36, 120, 57, 78],
    7: [36, 50, 92, 120, 106, 78, 64],
    10: [54, 36, 82, 101, 91, 63, 120, 110, 45, 73],
  },
  '18 Square': {
    5: [99, 36, 120, 57, 78],
    7: [36, 50, 92, 120, 106, 78, 64],
    10: [54, 36, 82, 101, 91, 63, 120, 110, 45, 73],
  },
  '24 Round': {
    5: [99, 36, 120, 57, 78],
    7: [36, 50, 92, 120, 106, 78, 64],
    10: [54, 36, 82, 101, 91, 63, 120, 110, 45, 73],
    14: [49, 114, 94, 68, 36, 120, 81, 101, 62, 42, 88, 75, 108, 55],
    17: [51, 88, 114, 72, 41, 103, 120, 98, 36, 56, 82, 46, 93, 66, 77, 108, 61],
  },
  '33 Round': {
    5: [99, 36, 120, 57, 78],
    7: [36, 50, 92, 120, 106, 78, 64],
    10: [54, 36, 82, 101, 91, 63, 120, 110, 45, 73],
    14: [49, 114, 94, 68, 36, 120, 81, 101, 62, 42, 88, 75, 108, 55],
    17: [51, 88, 114, 72, 41, 103, 120, 98, 36, 56, 82, 46, 93, 66, 77, 108, 61],
  },
  '24 Square': {
    5: [99, 36, 120, 57, 78],
    7: [36, 50, 92, 120, 106, 78, 64],
    10: [54, 36, 82, 101, 120, 63, 91, 110, 45, 73],
    14: [49, 114, 94, 68, 36, 120, 81, 101, 62, 42, 88, 75, 108, 55],
    17: [51, 88, 114, 72, 41, 103, 120, 98, 36, 56, 82, 46, 93, 66, 77, 108, 61],
  },
  '48 Square': {
    5: [99, 36, 120, 57, 78],
    7: [36, 50, 92, 120, 106, 78, 64],
    9: [29, 15, 44, 58, 30, 72, 51, 58, 27],
    10: [54, 36, 82, 101, 120, 63, 91, 110, 45, 73],
    14: [49, 114, 94, 68, 36, 120, 81, 101, 62, 42, 88, 75, 108, 55],
    16: [52, 29, 44, 40, 58, 58, 38, 29, 52, 38, 72, 72, 58, 24, 49, 29],
    17: [51, 88, 114, 72, 41, 103, 98, 120, 36, 56, 82, 46, 93, 66, 77, 108, 61],
    25: [52, 29, 44, 40, 58, 58, 38, 29, 52, 38, 72, 72, 58, 24, 49, 29, 15, 38, 72, 72, 58, 24, 49, 29, 15],
  },
  '36 x 96': {
    18: [52, 29, 44, 40, 58, 58, 38, 29, 52, 38, 72, 72, 58, 24, 49, 29, 15, 38],
    27: [52, 29, 44, 40, 58, 58, 38, 29, 52, 38, 72, 72, 58, 24, 49, 29, 15, 38, 72, 72, 58, 24, 49, 29, 15, 38, 52],
  },
},
'36 - 180 Wood': {
  '35 x 9': {
    5: [180, 36, 180, 36, 180],
    7: [180, 15, 44, 180, 15, 44, 180],
  },
  '35 x 12': {
    5: [180, 36, 180, 36, 180],
    7: [180, 15, 44, 180, 15, 44, 180],
    10: [55, 29, 15, 180, 46, 29, 180, 46, 15, 55],
  },
  '35 x 18': {
    10: [39, 60, 15, 46, 180, 55, 29, 15, 55, 46],
    14: [26, 49, 61, 40, 17, 32, 180, 54, 40, 17, 61, 44, 61, 28],
    17: [27, 53, 62, 41, 18, 42, 180, 54, 16, 28, 53, 20, 53, 34, 46, 62, 28],
  },
  '47 x 9': {
    5: [180, 36, 180, 36, 180],
    7: [180, 15, 44, 180, 15, 44, 180],
  },
  '47 x 12': {
   5: [180, 36, 180, 36, 180],
    7: [180, 15, 44, 180, 15, 44, 180],
    10: [53, 37, 15, 180, 46, 27, 180, 46, 15, 53],
    14: [63, 26, 51, 17, 180, 42, 180, 35, 42, 63, 51, 35, 21, 180],
    17: [25, 180, 58, 46, 20, 49, 180, 34, 16, 30, 60, 46, 21, 37, 51, 62, 30],
  },
  '47 x 24': {
    10: [38, 53, 15, 45, 180, 180, 26, 15, 53, 45],
    14: [23, 48, 62, 45, 17, 35, 180, 32, 45, 17, 57, 35, 48, 66],
    17: [25, 180, 57, 45, 19, 48, 180, 34, 16, 31, 60, 58, 34, 37, 51, 66, 31],
  },
  '70 x 12': {
   5: [180, 36, 180, 36, 180],
    7: [180, 15, 44, 180, 15, 44, 180],
    10: [53, 37, 15, 180, 46, 27, 180, 46, 15, 53],
    14: [63, 26, 51, 17, 180, 55, 180, 21, 42, 63, 51, 35, 21, 180],
    17: [25, 180, 58, 46, 20, 49, 180, 34, 16, 30, 58, 46, 21, 37, 51, 60, 30],
  },
  '12 Round': {
    3: [42, 15, 180],
    5: [53, 15, 180, 27, 46],
    7: [15, 24, 50, 180, 62, 44, 36],
    10: [29, 15, 44, 58, 180, 30, 51, 58, 27, 37],
  },
  '16 Round': {
    5: [53, 15, 180, 27, 46],
  },
  '18 Round': {
    5: [53, 15, 180, 27, 46],
    7: [15, 24, 50, 180, 62, 44, 36],
    10: [29, 15, 44, 58, 180, 30, 51, 58, 27, 37],
  },
  '18 Square': {
    5: [53, 15, 180, 27, 46],
    7: [62, 15, 50, 180, 24, 44, 36],
    10: [29, 15, 44, 58, 180, 30, 51, 58, 27, 37],
  },
  '24 Round': {
    5: [53, 15, 180, 27, 46],
    7: [62, 36, 50, 180, 24, 44, 15],
    10: [29, 15, 44, 58, 30, 51, 180, 58, 27, 37],
    14: [51, 29, 24, 15, 58, 44, 37, 180, 58, 180, 24, 50, 29, 58],
    17: [52, 29, 15, 15, 44, 58, 38, 29, 58, 38, 180, 52, 180, 24, 49, 29, 38],
  },
  '33 Round': {
    5: [53, 15, 180, 27, 46],
    7: [62, 36, 50, 180, 24, 44, 15],
    10: [29, 15, 44, 58, 30, 51, 180, 58, 27, 37],
    14: [51, 29, 24, 15, 58, 44, 37, 180, 58, 180, 24, 50, 29, 58],
    17: [52, 29, 15, 15, 44, 58, 38, 29, 58, 38, 180, 52, 180, 24, 49, 29, 38],
  },
  '24 Square': {
    5: [53, 15, 180, 27, 46],
    7: [62, 36, 50, 180, 24, 44, 15],
    10: [29, 15, 44, 58, 30, 180, 51, 58, 27, 37],
    14: [51, 29, 24, 15, 58, 44, 37, 180, 58, 180, 24, 50, 29, 58],
    17: [52, 29, 15, 15, 44, 58, 38, 29, 58, 38, 180, 52, 180, 24, 49, 29, 38],
  },
  '48 Square': {
    5: [53, 15, 180, 27, 46],
    7: [62, 15, 50, 180, 24, 44, 36],
    9: [29, 15, 44, 58, 30, 180, 51, 58, 27],
    10: [29, 15, 44, 58, 30, 180, 51, 58, 27, 37],
    14: [51, 29, 44, 40, 58, 15, 37, 180, 58, 180, 24, 50, 29, 58],
    16: [52, 29, 44, 40, 58, 58, 38, 29, 52, 38, 180, 180, 58, 24, 49, 29],
    17: [52, 29, 44, 40, 58, 58, 38, 29, 52, 38, 180, 180, 58, 24, 49, 29, 15],
    25: [52, 29, 44, 40, 58, 58, 38, 29, 52, 38, 180, 180, 58, 24, 49, 29, 15, 38, 180, 180, 58, 24, 49, 29, 15],
  },
  '36 x 96': {
    18: [52, 29, 44, 40, 58, 58, 38, 29, 52, 38, 180, 180, 58, 24, 49, 29, 15, 38],
    27: [52, 29, 44, 40, 58, 58, 38, 29, 52, 38, 180, 180, 58, 24, 49, 29, 15, 38, 180, 180, 58, 24, 49, 29, 15, 38, 52],
  },
},
'Step Long Modular': {
  
  '48 Square': {
    9: [15, 15, 15, 44, 44, 44, 72, 72, 72],
    16: [15, 15, 15, 15, 34, 34, 34, 34, 53, 53, 53, 53, 72, 72, 72, 72],
    25: [15, 15, 15, 15, 15, 29,29,29,29,29,44,44,44,44,44,58,58,58,58,58,72,72,72,72,72],
  },
  '36 x 96': {
    18: [15,15,15,26,26,26,38,38,38,50,50,50,61,61,61,72,72,72],
    27: [15,15,15,22,22,22,29,29,29,36,36,36,43,43,43,50,50,50,57,57,57,64,64,64,72,72,72],
  },
},
'Diagonal Step Long Modular': {
  
  '48 Square': {
    9: [15, 29, 44, 29, 44, 58, 44, 58, 72],
    16: [15, 24, 34, 43, 24, 34, 43, 53, 34, 43, 53, 62, 43, 53, 62, 72],
    25: [15, 22, 29, 36, 43, 22,29,36,43,50,29,36,43,50,57,36,43,50,57,64,43,50,57,64,72],
  },
  '36 x 96': {
    18: [15,15,15,15,15,44,15,44,44,44,44,72,44,72,72,72,72,72],
    27: [15,29,44,15,29,44,15,29,44,29,44,58,29,44,58,29,44,58,44,58,72,44,58,72,44,58,72],   
  },
},
'Diamond Long Modular': {
  '48 Square': {
    9: [15, 29, 44, 29, 44, 58, 44, 58, 72],
    16: [15, 24, 34, 43, 24, 34, 43, 53, 34, 43, 53, 62, 43, 53, 62, 72],
    25: [15,29,44,58,72,29,72,44,15,58,44,44,15,44,44,58,15,44,72,29,72,58,44,29,15],
  },
  '36 x 96': {
    18: [15,15,15,15,15,44,15,44,44,44,44,72,44,72,72,72,72,72],
    27: [15,29,44,15,29,44,15,29,44,29,44,58,29,44,58,29,44,58,44,58,72,44,58,72,44,58,72],   
  },
},
};
const SOCKET_TYPES = ['Modern Threaded','Modern', 'Vintage', 'Keyed', 'Threaded', 'Wood'];
const CAGE_TYPES = ['Diamond', 'Round', 'Hinge'];
const SHADE_SHAPES = [
  'Globe',
  '18" Woven Dome',
  '18" Woven Basket',
  '24" Woven Basket',
  '30" Woven Basket',
  '36" Woven Basket',
  '18" Woven Round',
  '24" Woven Round',
  '24" Woven Dome',
  '20" Deep Dome',
  'Cylinder',
  'Felt 10" Dome',
  'Felt 16" Dome',
  '12" Dome',
  '18" Dome',
  '24" Dome',
  '5" Dome',
  'Dome',
  'Felt Cone',
  'Cone',
  'Bell',
  'Ruffle',
  'Net',
  'Felt 20" Flat',
  'Small Flat',
  'Flat',
  'Tube',
  'Flare',
  'Radio',
  '5" Drum',
  '12" Drum',
  '16" Industrial',
  '12" Neckless',
  '16" Neckless',
  '10" Neckless',
  '8" Neckless',
  '14" Neckless',
  '5" Disk',
  '8" Disk',
  '12" Disk',
  '24" Disk',
  'Octagon',


];
const BULB_SHAPES = [
  'XL 11" Tube',
  'XL 8" Globe',
  'Uneven 5" Globe',
  'XL Oval',
  'Globe',
  'Tube',
  'Diamond',
  'Edison',
  'Radio',
  'Victorian',
  'A15',
  'XL Beehive',
  'XL Pear',
  'XL UFO',
  'XL Turret',
  'XL Teardrop',
  'XL Uneven Bottle',
  'XL Mushroom',
  'XL Bottle',
  'XL Gourd',
  'XL Uneven Pear',
  'XL Bubbles',
  'XL Antler',
  'XL Lined Teardrop',
  'Geoglobe',



];
const ROPE_SPECIALS = [
  'Magenta',
  'Rainbow',
  'Grey Dot',
  'Grey Tweed',
  'Chevron',
  'Green Tweed',
  'White Brass',
  'Terracotta Tweed',
];
const ATTRNAME_NUMBER_PENDANTS = 'Number of Pendants';
const FORM_ATTR_SEPERATOR = '-';
const ATTR_CREATE_ONCE = [
  ATTRNAME_NUMBER_PENDANTS,
  'Outgoing Wire',
  'Ceiling Plate',
  'Wood Stain',
  'Wood Type',
  'Product',
  'Hardware Finish',
  'Cluster Ring',
  'Metal Base',
];
const ATTR_EXCLUDED = ['Reload', 'Data Source'];
const BASE_SCENE_ATTRIBUTES = [
  'Wood Type',
  'Wood Stain',
  'Ceiling Plate',
  'Outgoing Wire',
  'Hardware Finish',
  'Cluster Ring',
  'Metal Base',
  ATTRNAME_NUMBER_PENDANTS,
];
const PENDANT_ATTRIBUTES = [
  'Round Cord Color',
  'Twisted Cord Color',
  'Socket',
  'Bulb',
  'Cage',
  'Shade',
  'Shade Fitter',
  'Ceiling Hooks',
];
const SWITCH_ATTRIBUTES = {
  'Cord Type': ['Round', 'Twisted', 'Thick','Chain','Rigid Stem'],
  'Cage / Shade': ['None', 'Cage', 'Shade'],
};
const LENGTH_ATTRIBUTES = ['Fixture Length', 'Length(Feet)', 'Length'];
const INDEXMAP = [
  0,
  2,
  4,
  5,
  7,
  8,
  10,
  12,
  13,
  15,
  16,
  18,
  1,
  3,
  6,
  9,
  11,
  14,
  17,
];

export {
  NODE_IDS,
  fetchedScenes,
  Colors,
  CEILING_PLATE_COLORS,
  VECTORS,
  ROPE_LENGTH,
  SOCKET_TYPES,
  CAGE_TYPES,
  SHADE_SHAPES,
  BULB_SHAPES,
  ROPE_SPECIALS,
  ATTRNAME_NUMBER_PENDANTS,
  FORM_ATTR_SEPERATOR,
  ATTR_CREATE_ONCE,
  ATTR_EXCLUDED,
  BASE_SCENE_ATTRIBUTES,
  PENDANT_ATTRIBUTES,
  SWITCH_ATTRIBUTES,
  LENGTH_ATTRIBUTES,
  INDEXMAP,
};
